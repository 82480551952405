<form class="needs-validation"
  [class.was-validated]="!!validSelectedAddress() && deliveryAddress().deliveryNotes != ''">
  <div class="row form-row my-5">
    <div class="col">
      <app-autocomplete-input (validSelectedAddress)="onValidDeliveryAddressSelected($event)"></app-autocomplete-input>
    </div>
  </div>
  @if (!!validSelectedAddress()) {
  <div class="row form-row animated fadeInDown">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div class="form-floating">
        <input type="text" class="form-control" name="additionalAddress" [class.is-valid]="true"
          [ngModel]="deliveryAddress().additionalAddress"
          (ngModelChange)="updateDeliveryAddress({additionalAddress: $event })"
          placeholder="Type your additional address information" />
        <label for="additionalAddressCtrl">Additional information</label>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div class="form-floating">
        <!-- [readonly]="!!googleAddress()?.zipCode" -->
        <input type="text" class="form-control" name="zipCode"
          [class.is-valid]="(deliveryAddress().zipCode?.length ?? 0) >= 5"
          [class.is-invalid]="(deliveryAddress().zipCode?.length ?? 0) < 5" [ngModel]="deliveryAddress().zipCode"
          (ngModelChange)="updateDeliveryAddress({zipCode: $event })" placeholder="Type your ZIP Code" required
          minlength="5" />
        <label for="zipCodeCtrl">ZIP Code</label>
        <div class="invalid-tooltip">
          Please provide a valid ZIP Code
        </div>
      </div>
    </div>
  </div>
  }

  @if (!!validSelectedAddress()) {
  <div class="row form-row animated fadeInDown">
    <div class="col">
      <div class="form-floating">
        <textarea class="form-control"
          placeholder="Please help our drivers find you and get your order to the right place." name="deliveryNotes"
          [class.is-valid]="deliveryAddress().deliveryNotes != ''" [ngModel]="deliveryAddress().deliveryNotes"
          (ngModelChange)="updateDeliveryAddress({deliveryNotes: $event})" style="height: 100px"></textarea>
        <label for="deliveryNotesCtrl">Please help our drivers find you and get your order to the right place.</label>
      </div>

    </div>
  </div>

  <div class="row form-row animated fadeInDown">
    <div class="col-12">
      <h6 class="signup__sub-head-text">We deliver to your area on {{deliveryOptions && deliveryOptions.length ?
        deliveryOptions[0].name : ''}}s</h6>
      <mat-form-field class="w-100" [class.option-selected]="!!selectedDeliveryDay()">
        <mat-label>Your Next Available Delivery Day is</mat-label>
        <input type="text" matInput [readonly]="true"
          [value]="deliveryOptions.length ? deliveryOptions[0].formattedDate : ''" placeholder="Delivery day">
      </mat-form-field>
    </div>
  </div>

  <div class="row form-row-action ">
    <div class="col">
      <div class="d-grid gap-2 mt-4 col-md-2 col-12 mx-auto">
        <button type="button" (click)="continue()"
          [disabled]="!selectedDeliveryDay() || !validSelectedAddress() || (deliveryAddress().zipCode?.length ?? 0) < 5"
          class="btn btn-secondary animated fadeIn">
          Continue
        </button>
      </div>
    </div>
  </div>
  }
</form>