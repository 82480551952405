import { Directive, ElementRef, HostListener } from '@angular/core';
import { formatCurrencyForInput } from '../utils/formatting';

@Directive({
  selector: '[appCurrencyInput]',
  standalone: true,
})
export class CurrencyInputDirective {
  private regex: RegExp = new RegExp('^[0-9]*[.]?[0-9]*$');

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(): void {
    let inputValue = this.el.nativeElement.value;

    if (!this.regex.test(inputValue))
      inputValue = inputValue.replace(/[^0-9.]|(?<=\.[^.]*)\./g, '');

    if (inputValue === '.')
      inputValue = '0.';

    if (inputValue.endsWith('.')) {
      inputValue = formatCurrencyForInput(inputValue.substring(0, inputValue.length - 1)) + '.';
      this.el.nativeElement.value = inputValue;
      return;
    }

    if (inputValue.indexOf('.') !== -1) {
      const parts = inputValue.split('.');
      inputValue = parts[0] + '.' + parts[1].substring(0, 2);
    }

    if (inputValue !== '')
      inputValue = formatCurrencyForInput(inputValue);

    this.el.nativeElement.value = inputValue;
  }
}
