export enum SignUpSteps {
  deliveryInformation = 0,
  personalInformation = 1,
  selectYourFarmbox = 2,
  preferences = 3,
  paymentInformation = 4
}

export enum InstagramMediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  CAROUSEL_ALBUM = 'CAROUSEL_ALBUM'
}

export enum TipOptions {
  RECURRENT = 'RECURRENT',
  ONE_TIME = 'ONE_TIME',
}
